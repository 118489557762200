import component from '@core/components/toastification/ToastificationContent.vue'
import ErrorHandler from '@/utils/error-handler'

const NotifyPlugin = {
  install(Vue: any, { toast }: any) {
    // eslint-disable-next-line no-param-reassign,no-multi-assign
    Vue.$notifySuccess = Vue.prototype.$notifySuccess = (title: string) => toast({
      component,
      props: { title, icon: 'CheckIcon', variant: 'success' },
    })

    // eslint-disable-next-line no-param-reassign,no-multi-assign
    Vue.$notifyError = Vue.prototype.$notifyError = (e: Error | string) => {
      const { title, message: text } = ErrorHandler.getErrorDetails(e)
      return toast({
        component,
        props: {
          title, text, icon: 'XIcon', variant: 'danger',
        },
      })
    }

    // eslint-disable-next-line no-param-reassign,no-multi-assign
    Vue.$notifyWarning = Vue.prototype.$notifyWarning = (title: string) => toast({
      component,
      props: { title, icon: 'AlertTriangleIcon', variant: 'warning' },
    })

    // eslint-disable-next-line no-param-reassign,no-multi-assign
    Vue.$notifyInfo = Vue.prototype.$notifyInfo = (title: string) => toast({
      component,
      props: { title, icon: 'InfoIcon', variant: 'info' },
    })
  },
}

export default NotifyPlugin
